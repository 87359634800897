<template>
  <div class="register" :class="$mq">
    <div class="panel">
      <div class="header heading-accent text-center">
        {{ $t('auth.register.title') }}
      </div>

      <ul class="tabs-header">
        <li :class="{ active: role === 'agent' }"
            @click="$router.push({ name: 'register', params: { role: 'agent' }})">
          {{ $t('auth.register.agentAccount') }}
        </li>
        <li :class="{ active: role === 'client' }"
            @click="$router.push({ name: 'register', params: { role: 'client' }})"
            v-html="$t('auth.register.clientAccount')">
        </li>
      </ul>

      <form-register-agent v-show="role === 'agent'"
                           @success="onRegisterSuccess"></form-register-agent>
      <form-register-client v-show="role === 'client'"
                            @success="onRegisterSuccess"></form-register-client>
    </div>
  </div>
</template>

<script>
  import FormRegisterClient from '@/components/Forms/FormRegisterClient.vue'
  import FormRegisterAgent  from '@/components/Forms/FormRegisterAgent.vue'
  import hasBackgroundMixin from '@/services/mixins/hasBackgroundMixin.js'

  import { login } from '@/services/auth'

  export default {
    props: {
      role: {
        type: String,
        default: 'agent'
      }
    },

    components: {
      FormRegisterClient,
      FormRegisterAgent
    },

    mixins: [hasBackgroundMixin],

    methods: {
      onRegisterSuccess(credentials) {
        let data = {
          engine: 'email',
          credentials,
          profile: {
            "gender": 'FEMALE',
            "meta_data": { "test": true }
          },
          long_live: true
        }

        login(data).then((response) => {
          this.setCurrentUser(response.user)
          this.setAuthorizationTokens({
            accessToken: response.access_token,
            expireToken: response.expire_token
          })

          window.location.reload()

          // This is made to properly redirect user to the home page when page reloads after logging in
          localStorage.setItem('routeToRedirect', JSON.stringify({ name: 'home' }))
        })
      },

      ...mapActions('auth', [
        'setCurrentUser',
        'setAuthorizationTokens',
      ])
    },

    data() {
      return {
        currentTab: 'client'
      }
    }
  }
</script>

<style lang="scss">
  .register {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 0 auto;
    max-width: 600px;

    .header {
      margin-bottom: 30px;
    }

    .panel {
      padding: 20px;
      margin-bottom: 20px;

      background: #FFFFFF;
      box-shadow: 0 6px 12px 0 rgba(54,76,106,0.20);
      border-radius: 8px;
    }

    .tabs-header {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0 0 30px 0;

      & > li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        padding: 8px;
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        color: $primary-color;
        border: 1px solid $primary-color;
        transition: all .2s;
        cursor: pointer;
        user-select: none;

        &:first-child {
          border-radius: 6px 0 0 6px;
          border-right: none;
        }

        &:last-child {
          border-radius: 0 6px 6px 0;
          border-left: none;
        }

        &.active {
          color: #fff;
          background-color: $primary-color;
          border-color: transparent;
        }
      }
    }

    &.mobile .tabs-header > li {
      font-size: 14px;
    }
  }
</style>
